<template>
  <div class="app-container">

    <list v-model="list" />

    <el-pagination
      background
      layout="total,sizes,prev,pager,next,jumper"
      :page-sizes="[10,20,30,50]"
      :page-size="params.pagesize"
      :current-page.sync="params.page"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

  </div>
</template>

<script>
import List from './components/list'
export default {
  components: {
    List
  },
  data() {
    return {
      params: {
        cate_id: null,
        pagesize: 20,
        page: 1
      },
      list: [],
      total: 0
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.params = Object.assign(this.params, this.$route.query)
      this.getList()
    },
    getList() {
      this.$api.article.list(this.params).then(response => {
        this.list = response.data
        this.total = response.total
      })
    },
    handleAdd() {
      this.$router.push('add')
    },
    handleSizeChange(val) {
      this.params.page = 1
      this.params.pagesize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.getList()
    }
  }
}
</script>
