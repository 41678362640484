<template>
  <el-table :data="value" border>
    <el-table-column label="标题" align="center">
      <template slot-scope="scope">
        <div @click="handleDetail(scope.row)">
          {{ item.title }}
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="created_at"
      label="时间"
      width="160"
      align="center"
    />
  </el-table>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleDetail(item) {
      this.$router.push({
        path: 'detail',
        query: {
          id: item.id
        }
      })
    }
  }
}
</script>
